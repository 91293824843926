const dialogManager = {
    openDialogId: null,
    initCover: () => {
        const cover = document.createElement('div');
        cover.classList.add('dialog-cover');
        document.body.appendChild(cover);
    },
    removeCover: () => {
        const cover = document.querySelector('.dialog-cover');
        if (cover) {
            cover.parentNode.removeChild(cover);
        }
    },
    blockBodyScroll: () => {
        const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.paddingRight = `${scrollWidth}px`;
        document.body.style.overflow = 'hidden';
    },

    removeBlockBodyScroll: () => {
        document.body.style.paddingRight = '';
        document.body.style.overflow = '';
    },
    handleTabNavigation: (dialog) => {
        const interactiveElements = Array.from(dialog.querySelectorAll('a, button, input, select, textarea'));
        let focusedElementIndex = 0;

        const focusElement = (index) => {
            interactiveElements[index].focus();
        };

        focusElement(focusedElementIndex);

        dialog.addEventListener('keydown', (e) => {
            if (e.key === 'Tab') {
                e.preventDefault();
                if (e.shiftKey) {
                    focusedElementIndex = (focusedElementIndex === 0) ? interactiveElements.length - 1 : focusedElementIndex - 1;
                } else {
                    focusedElementIndex = (focusedElementIndex === interactiveElements.length - 1) ? 0 : focusedElementIndex + 1;
                }
                focusElement(focusedElementIndex);
            }
        });
    },
    updateDialogHeight: (dialog) => {
        const dialogContent = dialog.querySelector('.dialog-content');
        const headerDialog = dialog.querySelector('h2');
        const buttonsArea = dialog.querySelector('.dialog-buttons');
        const headerHeight = headerDialog?.clientHeight || 0;
        const buttonsHeight = buttonsArea?.clientHeight || 0;
        const areaNotContent = headerHeight + buttonsHeight;
        const marginSpace = 64;

        if (deviceUtils.isMobile) {
            dialogContent.style.height = `calc(100% - ${areaNotContent}px)`;
        } else {
            dialogContent.style.maxHeight = `calc(100vh - ${areaNotContent + marginSpace}px)`;
        }
    },
    // Funkcja zamykająca poprzedni dialog i dodająca przycisk "Powrót"
    // Funkcja odpalana jest kiedy wywołanie modala następuje z otwartego modala
    handlePreviousDialog:(newDialog, currentDialogId, prevDialogId) => {
            dialogManager.closeDialog(prevDialogId);
            // Dodaj przycisk powrotu do nowego dialogu, jeśli jeszcze go nie ma
            if (!newDialog.querySelector('.return-button')) {
                const returnButton = document.createElement('button');
                returnButton.classList.add('dialog-return', 'return-button');
                returnButton.setAttribute('aria-label', 'Powrót');
                returnButton.innerHTML = `<span class="web-icon icon-arrow-left" aria-hidden="true"></span>`;

            returnButton.addEventListener('click', () => {
                // Dynamicznie znajdowanie przycisku w poprzednim modalu
                const button = $(`[data-id-modal='${currentDialogId}']`);
                    dialogManager.closeDialog(currentDialogId);
                    dialogManager.openDialog(prevDialogId);
                    button[0].focus();
            });
            newDialog.prepend(returnButton);
        }
    },
    initAndOpenDialog : (dialog, modalId, openDialogButton) => {
        dialogManager.openDialogId = modalId;
        dialog.setAttribute('open', '');
        dialog.setAttribute('aria-hidden', 'false');
        dialogManager.blockBodyScroll();
        dialogManager.initCover();
        dialogManager.handleTabNavigation(dialog);
        dialogManager.updateDialogHeight(dialog);

        const dialogButtons = [...dialog.querySelectorAll('[class*="dialog-close"], .dialog-buttons > a')];
        const closeDialog = () => {
            dialogButtons.forEach(btn => btn.removeEventListener('click', closeDialog));
            dialogManager.closeDialog(modalId, openDialogButton);
        };
        dialogButtons.forEach(btn => btn.addEventListener('click', closeDialog));
        document.addEventListener('keydown', dialogManager.addESC);
    },
    openDialog: (modalId, openDialogButton) => {
        const dialog = document.getElementById(modalId);
        const prevDialogId = dialogManager.openDialogId;

        // Jeśli to pierwszy otwierany dialog, zapisuje informacje, przy całkowitym zamykaniu stosu modalu
        // focus musi wrócić do orginalnego wywołania
        if (!dialogManager.openDialogButton) {
            dialogManager.openDialogButton = openDialogButton;
        }
        const firstDialogTriggerButton = dialogManager.openDialogButton;

        // Jeśli istnieje otwarty dialog, obsługujemy otwieranie nowego modalu
        if (prevDialogId) {
            dialogManager.handlePreviousDialog(dialog, modalId, prevDialogId);
        }
        dialogManager.initAndOpenDialog(dialog, modalId, firstDialogTriggerButton);
    },
    closeDialog: (modalId, openDialogButton = null) => {
        const dialog = document.getElementById(modalId);
        dialogManager.removeBlockBodyScroll();
        dialogManager.removeCover();
        if (dialog) {
            const returnButton = dialog.querySelector('.return-button');
            if (returnButton && openDialogButton) {
                returnButton.remove();
            }
            dialogManager.openDialogId = null;
            dialog.removeAttribute('open');
            dialog.setAttribute('aria-hidden', 'true');
            document.body.classList.remove('dialog-open');
            document.removeEventListener('keydown', dialogManager.addESC);
            if (openDialogButton) {
                openDialogButton.focus();
            }

            dialogManager.openDialogButton = null;
        }
    },
    addESC: e => {
        if (e.key === 'Escape') {
            const dialog = document.querySelector('[role="dialog"][open]');
            if (dialog) {
                const closeButton = dialog.querySelector('.dialog-close');
                if (closeButton) {
                    closeButton.click();
                }
            }
        }
    },
    init: () => {
        const isUserPart = !ComponentsManager.isBuilder();
        if (isUserPart) {
            const dialogButtons = document.querySelectorAll('[data-show="dialog"]');
            dialogButtons.forEach(button => {
                if (button.tagName === 'A') {
                    button.setAttribute('role', 'button');
                    button.setAttribute('aria-haspopup', 'dialog');
                }

                button.addEventListener('click', event => {
                    const target = event.currentTarget;
                    event.preventDefault();
                    event.stopPropagation();

                    const modalId = target.getAttribute('data-id-modal') || target.getAttribute('href').replace('#', '');

                    dialogManager.openDialog(modalId, button);
                });
            });
        }
    }
};

$("#outlet-content").on('content-replace', function() {
    if (document.querySelector('.dialog-cover')) {
        dialogManager.closeDialog();
    }
    setTimeout(() => {
        dialogManager.init(); // Inicjalizujemy dialog po 1 sekundzie, bo musimy poczekać na INGDS
    }, 1000);
});
