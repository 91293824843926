const dialogManager = {

    initCover: () => {
        const cover = document.createElement('div');
        cover.classList.add('dialog-cover');
        document.body.appendChild(cover);
    },
    removeCover: () => {
        const cover = document.querySelector('.dialog-cover');
        if (cover) {
            cover.parentNode.removeChild(cover);
        }
    },
    handleTabNavigation: (dialog) => {
        const interactiveElements = Array.from(dialog.querySelectorAll('a, button, input, select, textarea'));
        let focusedElementIndex = 0;

        const focusElement = (index) => {
            interactiveElements[index].focus();
        };

        focusElement(focusedElementIndex);

        dialog.addEventListener('keydown', (e) => {
            if (e.key === 'Tab') {
                e.preventDefault();
                if (e.shiftKey) {
                    focusedElementIndex = (focusedElementIndex === 0) ? interactiveElements.length - 1 : focusedElementIndex - 1;
                } else {
                    focusedElementIndex = (focusedElementIndex === interactiveElements.length - 1) ? 0 : focusedElementIndex + 1;
                }
                focusElement(focusedElementIndex);
            }
        });
    },
    openDialog: (modalId, button) => {
        const dialog = document.getElementById(modalId);
        if (dialog) {
            const dialogContent = dialog.querySelector('.dialog-content');
            const headerDialog = dialog.querySelector('h2');
            const buttonsArea = dialog.querySelector('.dialog-buttons');
            dialog.setAttribute('open', '');
            dialog.setAttribute('aria-hidden', 'false');
            CmsModalUtils.blockBodyScroll();
            dialogManager.initCover();
            dialogManager.handleTabNavigation(dialog);

            // Obliczenie wysokości Content dialog
            const headerHeight = headerDialog?.clientHeight;
            const buttonsHeight = buttonsArea?.clientHeight || 0;
            const areaNotContent = headerHeight + buttonsHeight;
            const marginSpace = 64;

            if (deviceUtils.isMobile) {
                dialogContent.style.height = `calc(100vh - ${areaNotContent}px)`;
            }
            else {
                dialogContent.style.maxHeight = `calc(100vh - ${areaNotContent + marginSpace}px)`;
            }

            const closeDialog = () => dialogManager.closeDialog(modalId, button);

            const dialogButtons = [...dialog.querySelectorAll('[class*="dialog-close"], .dialog-buttons > a')];
            dialogButtons.forEach(button => button.addEventListener('click', closeDialog));
            document.addEventListener('keydown', dialogManager.addESC);
        }
    },
    closeDialog: (modalId, button) => {
        const dialog = document.getElementById(modalId);
        if (dialog) {
            document.body.classList.remove('dialog-open');
            CmsModalUtils.removeBlockBodyScroll();
            dialogManager.removeCover();
            dialog.removeAttribute('open');
            dialog.setAttribute('aria-hidden', 'true');
            button.focus();
            document.removeEventListener('keydown', dialogManager.addESC);
        }
    },
    addESC: e => {
        if (e.key === 'Escape') {
            const dialog = document.querySelector('[role="dialog"][open]');
            if (dialog) {
                const closeButton = dialog.querySelector('.dialog-close');
                if (closeButton) {
                    closeButton.click();
                }
            }
        }
    },
    init: () => {
        const isUserPart = !ComponentsManager.isBuilder() && !ComponentsManager.isPreview();
        if (isUserPart) {
            const dialogButtons = document.querySelectorAll('[data-show="dialog"]');
            dialogButtons.forEach(button => {
                button.addEventListener('click', event => {
                    const modalId = event.target.getAttribute('data-id-modal');
                    dialogManager.openDialog(modalId, button);
                });
            });
        }
    }
};

$("#outlet-content").on('content-replace', function() {
    dialogManager.closeDialog();
    setTimeout(() => {
        dialogManager.init();
    }, 1000)
});
